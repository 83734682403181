/*
 * @Descripttion:
 * @Author: lqzh
 * @Date: 2020-02-28 16:31:28
 * @LastEditTime: 2020-02-28 16:54:05
 */
import axios from "./lib/axios.min.js";


if ('ontouchstart' in document) {
    document.body.addEventListener('touchstart', showMap, true);
    document.body.addEventListener('touchend', hideMap, true);
} else {
    document.body.addEventListener('mouseup', hideMap, true);
    document.body.addEventListener('mousedown', showMap, true);
}
document.body.addEventListener('contextmenu', e => {
    e.preventDefault();
});

axios.get('//api.zhuzhou.city/fun/people2/daily/json').then(res => {
    if (res.data.statusCode != 200) throw new Error(res.data.msg);
    document.querySelector("#ajaxDes").textContent = res.data.result.title + "...";
    document.querySelector(".top").dataset.src = res.data.result.link;
});

const todayPic = `//api.zhuzhou.city/fun/people2/daily/pic?${new Date().toLocaleDateString().replace(/\//g, '')}`;
document.querySelector(".cover").setAttribute('src', todayPic);
document.querySelector(".slogan").style["background-image"] = `url(${todayPic})`;

let timer = 0;
function showMap(event) {

    clearTimeout(timer);
    timer = setTimeout(() => {
        event.stopPropagation();
        event.preventDefault();
        document.body.classList.add('showMap');
        console.log('down', event.type);
    }, 500);
}
function hideMap(event) {

    clearTimeout(timer);
    document.body.classList.remove('showMap');
    console.log('up', event.type);
}


function openSite(type) {
    switch (type) {
        case 'p2':
            window.open(document.querySelector(".top").dataset.src);
            break;
        case 'bike':
            window.open("//bike.zhuzhou.city");
            break;
        case 'parking':
            window.open("//parking.zhuzhou.city");
            break;
        case 'bus':
            window.open("/bus/");
            break;
        case 'wc':
            window.open("//wc.zhuzhou.city");
            break;
    }
}
window.openSite = openSite;